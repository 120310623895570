<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar
            :temPermissao="
              $store.getters['usuario/temPermissao']('inserir.areas') &&
              items.length < $store.state.plano.planoAtual.numero_areas
            "
            to="/areas/adicionar"
          />
        </v-col>
      </v-row>
    </v-card-actions>

    <v-row class="mt-4">
      <v-col>
        <v-card class="pa-2 elevation-0 text-body-1">
          Áreas Disponíveis:
          {{ items.length }} |
          {{ $store.state.plano.planoAtual.numero_areas }}
        </v-card>

        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="items"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarAreasEmpresas"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-btn-mostrar
              :temPermissao="
                $store.getters['usuario/temPermissao']('mostrar.areas')
              "
              :href="`/areas/${item.id}/mostrar`"
            />
            <table-btn-editar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.areas')
              "
              :href="`/areas/${item.id}/editar`"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import empresas from "@/api/empresas";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      carregando: false,

      totalRegistros: 0,
      idExcluir: null,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "nome" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      items: [],
      paginacao: {},
    };
  },

  methods: {
    async carregarAreasEmpresas() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const empresa_id = this.$store.state.usuario.usuario.empresa_id;

        const resposta = await empresas.listarAreasEmpresa(empresa_id, {
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        });

        this.items = resposta.data.data;
        
        this.items = this.items.map((item) => {
          item.status = item.ativo == 1 ? "Ativo" : "Inativo";
          return item;
        });

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
